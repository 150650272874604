import { SystemLanguage, VpEnvironment } from '@zeiss/ng-vis-common/types';

import { Environment } from './environment';

export const App = {
	Name1: 'VISION',
	Name2: 'PORTAL',
	NameFull: 'The Vision Portal',
	AppEntered: new Date(),
	LocalStorage: {
		Prefix: `VISIONPORTAL${
			Environment.AppMeta.Environment !== VpEnvironment.PRD ? '_' + Environment.AppMeta.Environment : ''
		}_`,
		Settings: 'SETTINGS',
		Logs: 'LOGS',
	},
	AnnouncementCheckFirstDelay: 10000,
	AnnouncementCheckInterval: 60000,
	DefaultLanguage: SystemLanguage['en-GB'],
};

export const Api = {
	ESB: {
		Root: {
			Path: {
				[VpEnvironment.DEV]: 'https://esb-dev.zeiss.com',
				[VpEnvironment.QAS]: 'https://esb-stage.zeiss.com',
				[VpEnvironment.PRD]: 'https://esb.zeiss.com',
			},
			Header: {
				ESB: {
					[VpEnvironment.DEV]: {
						'EsbApi-Subscription-Key': 'ff484cb1216c447aa56d332231762390',
					},
					[VpEnvironment.QAS]: {
						'EsbApi-Subscription-Key': 'db311c807b0c43f380a64635cb013047',
					},
					[VpEnvironment.PRD]: {
						'EsbApi-Subscription-Key': '9be378e7f32f46e497c7fa33a920df4a',
					},
				},
				PublicWebServices: {
					[VpEnvironment.DEV]: {
						'Ocp-Apim-Subscription-Key': 'b06d026a2ede418db9d45c2d77e71ce3',
					},
					[VpEnvironment.QAS]: {
						'Ocp-Apim-Subscription-Key': '97c577a1488842dcbe6d1cc1c1c9e7ea',
					},
					[VpEnvironment.PRD]: {
						'Ocp-Apim-Subscription-Key': '2bfe1e8335d24dee8afbd0ed5f674ea7',
					},
				},
				CoreWebServices: {
					[VpEnvironment.DEV]: {
						'Ocp-Apim-Subscription-Key': 'b7e855153ada4c05b41d78a0f865c5a0',
					},
					[VpEnvironment.QAS]: {
						'Ocp-Apim-Subscription-Key': 'a251a1c68f41493eb1d2bfe7c2162b3c',
					},
					[VpEnvironment.PRD]: {
						'Ocp-Apim-Subscription-Key': '1a2dfe1293564ba4824b6a1da47d16f5',
					},
				},
				MDDB: {
					[VpEnvironment.DEV]: { 'Ocp-Apim-Subscription-Key': 'f9468785f62e4e7195e24af9e035d287' },
					[VpEnvironment.QAS]: { 'Ocp-Apim-Subscription-Key': '36727c0437904d53a87dff6d3d8fbc02' },
					[VpEnvironment.PRD]: { 'Ocp-Apim-Subscription-Key': '9465a2b377914576ab15ca82694d9cfb' },
				},
			},
		},
		Experience: {
			Path: '/public/api/experience/vis',
			URLShortener: {
				Get: '/shorturllist',
				Post: '/createshorturl',
				Delete: '/deleteshorturl',
			},
			OtdE2e: {
				AnalysisRefresh: '/refreshe2ereport',
			},
			InviteWebService: {
				Path: `/vis-aad-invite`,
				Headers: {
					DEV: {
						'Ocp-Apim-Subscription-Key': '14eadb65d14c44b8b457d3df7da4ba70',
					},
					QAS: {
						'Ocp-Apim-Subscription-Key': 'f061fc769b9a4682ac89847da7ab109c',
					},
					PRD: {
						'Ocp-Apim-Subscription-Key': '14eadb65d14c44b8b457d3df7da4ba70',
					},
				},
			},
			InviteCITWebHook: {
				Path: 'https://ac08a528-5e8f-4c44-b1ed-703cc5cb558e.webhook.we.azure-automation.net/webhooks?token=7R%2by75cgKuS9PUgOib3X4XdWcTnxbwFMcV54JegMdSw%3d',
			},
		},
		Mddb: {
			Path: '/public/api/vis/mddb/proxy/core',
			Endpoint: {
				MetaData: {
					GetMasterCategorys: '/meta/mastercategory/',
					GetParameterGroups: '/meta/parametergroup/',
					GetParameterTypes: '/meta/parametertype/',
					GetParameterTypeCharacteristics: '/meta/parametertypecharacteristic/',
					GetParameterCharacteristics: '/meta/parametercharacteristic/',
					GetPredefinedValues: '/meta/predefinedvalue/',
					GetUnits: '/meta/unit/',
					GetVisualType: '/meta/visualType/',
					GetOperatingStatus: '/meta/operatingstatus/',
					GetValidations: '/meta/validation/',
					GetDdDependency: '/meta/dropdowndependency/',
					GetDddValues: '/meta/dependencydropdownvalue/',
					GetDddPredefValues: '/meta/dependencydropdownpredefinedvalue/',
					GetOperatingParameters: '/operating/operatingparameter/',
					GetLanguage: '/Language/',
					GetOperatingParameterValues: '/operating/operatingparametervaluetype/',
					GetChecklists: '/checklist/checklist/',
				},
				CoreFunctions: {
					Master: '/master/',
					Object: '/object/',
					GenerateRandom: '/generate-random?length=8',
					Attachment: '/attachment',
					GetZPLLabel: '/label/',
					GetObjectRelations: '/objectrelationship/',
					Upload: '/upload',
					Download: '/download',
				},
				OperatingData: {
					GetOperatingData: '/operating/data/',
					GetOperatingPredefinedValues: '/operating/operatingpredefinedvalue/',
				},
				Misc: {
					Documentation: '/doc',
				},
				Checklist: {
					checklistMaster: '/checklist/checklistmaster/',
					checklistObject: '/checklist/checklistobject/',
				},
			},
		},
		LodbUtils: {
			Path: '/public/api/vis/lodbutils',
			Endpoint: {
				Messages: {
					DeleteMessages: '/deletemessages',
				},
			},
		},
		AKS: {
			Path: {
				NE: '/public/api/vis/kc/ne',
				WE: '/public/api/vis/kc/we',
			},
			Endpoint: {
				Namespaces: '/list-namespaces',
				Deployments: '/list-deployments',
				ScaleDeployments: '/scale-deployment',
				Pods: '/list-pods',
				PodLogs: '/pod-logs',
				Pod: '/describe-pod',
				NodeDetails: '/node-details',
			},
		},
		MDM: {
			Path: '/public/api/core/vis/mdm',
			Endpoint: {
				Language: {
					GetLanguage: '/language',
				},
				OtdE2E: {
					OtdE2EReport: '/otde2ereport',
					OtdE2EReportLock: '/otde2ereportlock',
					OtdE2EReportType: '/otde2ereporttype',
				},
				OtdRx: {
					OtdRxReport: '/otdrxreport',
					OtdRxReportLock: '/otdrxreportlock',
					OtdRxLabs: '/otdrxlab',
				},
				Utilities: {
					Blob: '/blob',
					ConvertXmlToJson: '/convertxmltojson',
				},
				Status: {
					StatusLocal: '/statuslocal',
					StatusLocalText: '/statuslocaltext',
					StatusGlobal: '/statusglobal',
					StatusMap: '/statusmap',
				},
				Access: {
					Access: '/access',
				},
				AccessManagement: {
					MgmtAccounts: '/mgmtaccounts',
					MgmtRoutes: '/mgmtroutes',
					MgmtTaskTypePerUser: '/mgmttasktypeperuser',
					MgmtUserPerTaskType: '/taskresponsibleuser',
				},
				Task: {
					Task: '/task',
					TaskRequest: '/taskrequest',
					TaskType: '/tasktype',
				},
				SCM: {
					ScmSalesOrg: '/scmsalesorg',
					ScmRoute: '/scmroute',
					ScmAttribute: '/scmattribute',
					ScmEntity: '/scmentity',
					ScmEntityType: '/scmentitytype',
				},
				DDP: {
					DdpStatusMap: '/ddpstatusmap',
					DdpMeta: '/ddpmeta',
					DdpShift: '/ddpshift',
					DdpHoliday: '/ddpholiday',
					DdpOrderTypeStatusMap: '/ddpordertypestatusmap',
					DdpOrderTypeStateMap: '/ddpordertypestatemap',
					DdpOrderTypeEntityAttributeMap: '/ddpordertypeentityattributemap',
					DdpCutOffCalc: '/ddpcutofftimecalc',
					DdpCutOffHoliday: '/ddpcutoffholiday',
				},
				BP: {
					Info: '/bpinfo',
					Data: '/bpdata',
					Notification: '/bpnotification',
					MasterData: '/bpmasterdata',
				},
				Omr: {
					Report: '/omrreport',
				},
				SyncGroups: {
					SyncGroups: '/syncgroup',
				},
				Lpp: {
					LensPass: '/lenspass',
				},
				Documentation: {
					GetDocumentation: '/documentation',
				},
				PullRequest: {
					GetPullRequests: '/pullrequest',
					UpdatePullRequest: '/pullrequest',
					DeleteStorageAccounts: '/pullrequest',
				},
				Announcement: {
					Announcements: '/announcement',
				},
			},
		},
		VP: {
			Path: '/public/api/core/vis/vp',
			Roles: '/accountmanagement/roles',
		},
	},
	Other: {
		MessageMover: {
			Path: 'https://prod-27.northeurope.logic.azure.com/workflows/7b14294441034b16be460c5efcb516d9/triggers/manual/paths/invoke',
			Parameter: {
				'api-version': '2016-10-01',
				sp: '/triggers/manual/run',
				sv: '1.0',
				sig: 'u2fzeDl1a0naT-_X_NwJ4UQALaOycNDBp-3fB1hV8T4',
			},
			Headers: {
				'Content-Type': 'application/json',
			},
		},
		BlobURL: {
			Path: 'https://prod-27.northeurope.logic.azure.com/workflows/467c3cbbce70443ba43361e1fe60b07a/triggers/manual/paths/invoke',
			Parameter: {
				'api-version': '2016-10-01',
				sp: '/triggers/manual/run',
				sv: '1.0',
				sig: 'oLKVErHgg0MV_k_6wEUKapjbpkQcIaQUSsUJuOpyvgk',
			},
			Headers: {
				'Content-Type': 'application/json',
			},
		},
		Translation: {
			Path: 'https://api.cognitive.microsofttranslator.com/translate',
			Parameter: {
				'api-version': '3.0',
			},
			Headers: {
				'Ocp-Apim-Subscription-Key': 'f6bc65825315433fa8f4a946dd264ec4',
			},
		},
	},
};

export interface YearlyEvent {
	greeting: string;
	date: number;
	img: string;
	daysBefore?: number;
	daysAfter?: number;
}

export const YEARLY_EVENTS: YearlyEvent[] = [
	/**
	 * Easter
	 */
	{
		greeting: 'Happy easter!',
		date: Date.UTC(2023, 3, 9),
		daysBefore: 6,
		daysAfter: 4,
		img: 'easter/easter.png',
	},
	{
		greeting: 'Happy easter!',
		date: Date.UTC(2024, 2, 31),
		daysBefore: 6,
		daysAfter: 4,
		img: 'easter/easter.png',
	},
	{
		greeting: 'Happy easter!',
		date: Date.UTC(2025, 3, 20),
		daysBefore: 6,
		daysAfter: 4,
		img: 'easter/easter.png',
	},
	/**
	 * Christmas
	 */
	{
		greeting: 'Merry christmas!',
		date: Date.UTC(2022, 11, 24),
		daysBefore: 23,
		daysAfter: 7,
		img: 'christmas/christmas.png',
	},
	{
		greeting: 'Merry christmas!',
		date: Date.UTC(2023, 11, 24),
		daysBefore: 23,
		daysAfter: 6,
		img: 'christmas/christmas.png',
	},
	{
		greeting: 'Merry christmas!',
		date: Date.UTC(2024, 11, 24),
		daysBefore: 23,
		daysAfter: 6,
		img: 'christmas/christmas.png',
	},
	{
		greeting: 'Merry christmas!',
		date: Date.UTC(2025, 11, 24),
		daysBefore: 23,
		daysAfter: 6,
		img: 'christmas/christmas.png',
	},
	/**
	 * Chinese New Year
	 */
	{
		greeting: 'Happy year of the rabbit!',
		date: Date.UTC(2023, 0, 22),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/rabbit.png',
	},
	{
		greeting: 'Happy year of the dragon!',
		date: Date.UTC(2024, 1, 10),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/dragon.png',
	},
	{
		greeting: 'Happy year of the snake!',
		date: Date.UTC(2025, 0, 29),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/snake.png',
	},
	{
		greeting: 'Happy year of the horse!',
		date: Date.UTC(2026, 1, 17),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/horse.png',
	},
	{
		greeting: 'Happy year of the goat!',
		date: Date.UTC(2027, 1, 6),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/goat.png',
	},
	{
		greeting: 'Happy year of the monkey!',
		date: Date.UTC(2028, 0, 26),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/monkey.png',
	},
	{
		greeting: 'Happy year of the rooster!',
		date: Date.UTC(2029, 1, 13),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/rooster.png',
	},
	{
		greeting: 'Happy year of the dog!',
		date: Date.UTC(2030, 1, 3),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/dog.png',
	},
	{
		greeting: 'Happy year of the pig!',
		date: Date.UTC(2031, 0, 23),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/pig.png',
	},
	{
		greeting: 'Happy year of the rat!',
		date: Date.UTC(2032, 1, 11),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/rat.png',
	},
	{
		greeting: 'Happy year of the ox!',
		date: Date.UTC(2033, 0, 31),
		daysBefore: 1,
		daysAfter: 14,
		img: 'chinese-new-year/ox.png',
	},
	/**
	 * Ramadan
	 */
	{
		greeting: 'Ramadan Kareem!',
		date: Date.UTC(2023, 2, 23),
		daysAfter: 28,
		img: 'ramadan/ramadan.png',
	},
];
