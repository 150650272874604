import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableCounterComponent } from './table-counter.component';

@NgModule({
	declarations: [TableCounterComponent],
	imports: [CommonModule],
	exports: [TableCounterComponent],
})
export class TableCounterModule {}
