export enum LogCat {
	'app' = 'app',
	'auth' = 'auth',
	'xml_convert' = 'xml_convert',
	'msg_delete' = 'msg_delete',
	'admin' = 'admin',
	'otde2e' = 'otde2e',
	'otdrx' = 'otdrx',
	'oct' = 'oct',
	'tst' = 'tst',
	'task' = 'task',
	'mddb' = 'mddb',
	'docu' = 'docu',
	'blob' = 'blob',
	'clipboard' = 'clipboard',
	'dashboard' = 'dashboard',
	'settings' = 'settings',
	'gss' = 'gss',
	'sync' = 'sync',
	'msgmove' = 'msgmove',
	'rxPass' = 'rxPass',
	'urlshortener' = 'urlshortener',
	'featurebranch' = 'featurebranch',
	'tutorial' = 'tutorial',
	'ddp' = 'ddp',
	'omr' = 'omr',
	'lpp' = 'lpp',
	'gbp' = 'gbp',
	'bookmark' = 'bookmark',
	'aks' = 'aks',
}
