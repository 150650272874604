import { VpEnvironment } from '@zeiss/ng-vis-common/types';

export const Environment = {
	AppMeta: {
		Environment: VpEnvironment.DEV,
		Root: 'https://portal-dev.vision.zeiss.com',
	},
	AppInsights: {
		Activated: document.URL.includes('localhost') ? false : true,
		InstrumentationKey: '28265bdc-dd5c-4000-9350-3349298346d3',
		TENANT_ID: '8cd48dd0-e5b6-42f4-946a-84377e7b1185',
		SUBSCRIPTION_ID: 'b1c34dd8-4c03-4040-8d17-49f42f2aa49b',
		RESOURCE_GROUP: 'PORTAL-DEV',
		APP_INSIGHTS_INSTANCE_NAME: 'visionportal-ai-dev',
	},
	LOI: {
		Path: 'https://loi-dev.lodb.org/',
	},
};
