import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgVisAccessorsModule } from '@zeiss/ng-vis-common/accessors';
import {
	NgVisAccordionModule,
	NgVisBadgeModule,
	NgVisBoxContentModule,
	NgVisBoxFooterModule,
	NgVisBoxHeaderModule,
	NgVisBoxModule,
	NgVisCodeEditorModule,
	NgVisDividerModule,
	NgVisHeaderbarPortalModule,
	NgVisHrefExternalModule,
	NgVisIconModule,
	NgVisNavBoardModule,
	NgVisInfoModule,
	NgVisItemModule,
	NgVisCountryFlagModule,
} from '@zeiss/ng-vis-common/components';
import {
	NgVisAttributeModule,
	NgVisAutofocusModule,
	NgVisBusyModule,
	NgVisClipboardModule,
	NgVisTooltipModule,
} from '@zeiss/ng-vis-common/directives';
import {
	NgVisAgoModule,
	NgVisDateModule,
	NgVisShortenModule,
	NgVisTextHighlightModule,
} from '@zeiss/ng-vis-common/pipes';
import { TableCounterModule } from './table-counter/table-counter.module';
import { VpRuleProtectDirectiveModule } from '@zeiss/ng-vis-vp-auth/directives';

@NgModule({
	imports: [],
	exports: [
		FormsModule,
		NgVisAccessorsModule,
		NgVisAccordionModule,
		NgVisAttributeModule,
		NgVisAutofocusModule,
		NgVisBadgeModule,
		NgVisBoxContentModule,
		NgVisBoxFooterModule,
		NgVisBoxHeaderModule,
		NgVisBoxModule,
		NgVisBusyModule,
		NgVisClipboardModule,
		NgVisCodeEditorModule,
		NgVisDateModule,
		NgVisDividerModule,
		NgVisHeaderbarPortalModule,
		NgVisHrefExternalModule,
		NgVisIconModule,
		NgVisInfoModule,
		NgVisItemModule,
		NgVisNavBoardModule,
		NgVisTextHighlightModule,
		NgVisTooltipModule,
		PortalModule,
		ReactiveFormsModule,
		TableCounterModule,
		NgVisCountryFlagModule,
		NgVisAgoModule,
		NgVisShortenModule,
		VpRuleProtectDirectiveModule,
	],
})
export class GlobalImportsModule {}
